import { Button, Col, Row, Space, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CreateCustomerDrawer } from '../../DocumentSection/components/CreateCustomerDrawer'
import { documentAction } from '../../DocumentSection/state/actions'
import { UserAddOutlined,PlusCircleOutlined } from "@ant-design/icons";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { accountHandle } from '../../Account/state/actions'
import axios from 'axios'

const HomeOptions = () => {
    const {Text} = Typography
    const dispatch = useDispatch();
    const history = useHistory();
    const { drawerVisible } = useSelector((state) => state.documentSection.CorpReducer);
    const { getLicenseData } = useSelector((state) => state.account);

    let unmounted = {
        value: false
    };
    let source = axios.CancelToken.source();

    const handleCreateContact = () => {
        dispatch(documentAction.setCorpState({ drawerVisible: true }));
    }
    const drawerClose = () => {
        dispatch(documentAction.clearCustomerData());
        dispatch(documentAction.setCorpState({ drawerVisible: false }));
    };

    const handleTransactions = () => {
        history.push({
            pathname:"/transactions",
        });
    }

    const handleCreateListing = () => {
        history.push({
            pathname:"/transactions",
            state:{modalState: {isListingModal:true}}
        });
    }

    const handleCreateOffer = () => {
        history.push({
            pathname:"/transactions",
            state:{modalState: {isOfferModal:true}}
        });
    }

    useEffect(() => {
        if(!getLicenseData){
          dispatch(accountHandle.getAllLicense({source,unmounted}))
          return () => {
            unmounted.value = true;
            source.cancel("Cancelling in cleanup");
          }
        }
      },[])

  return (
    <>
        <CreateCustomerDrawer visible={drawerVisible} onClose={drawerClose} nameData="contact" />
        
        <Space direction='vertical' style={{display:"flex",alignItems:"center",marginTop:"30px"}}>
            <Col>
                <Row align={'middle'} style={{marginBottom:"20px", cursor:"pointer",color:"#19376D"}} onClick={handleCreateOffer}>
                    {/* <Button style={{padding:"30px 40px", height:"50px", lineHeight:"0px", fontSize:"22px"}}>+</Button>
                    <Text style={{marginLeft:"10px", fontSize:"19px", fontWeight:"bold",color:"#19376D"}}>Create Offer</Text> */}
                     <Button
                        style={{
                        background: "#3a74b6",
                        color: "whitesmoke",
                        fontWeight: "bold",
                        borderRadius: "1px",
                        border: "none",
                        display: "flex",
                        alignItems: "center",
                        padding:"25px 77px"
                        }}
                    >
                        <PlusCircleOutlined style={{fontSize: "20px", strokeWidth: "30", stroke: "whitesmoke"}}/> <div style={{marginTop: "-2px",fontSize:"20px"}}>Offer</div>
                    </Button>
                </Row>

                <Row align={'middle'} style={{marginBottom:"20px", cursor:"pointer",color:"#19376D"}} onClick={handleCreateListing}>
                    {/* <Button style={{padding:"30px 40px", height:"50px", lineHeight:"0px", fontSize:"22px"}}>+</Button>
                    <Text style={{marginLeft:"10px", fontSize:"19px", fontWeight:"bold",color:"#19376D"}}>Create Listing</Text> */}
                    <Button
                        style={{
                        background: "#3a74b6",
                        color: "whitesmoke",
                        fontWeight: "bold",
                        borderRadius: "1px",
                        border: "none",
                        display: "flex",
                        alignItems: "center",
                        padding:"25px 70px",
                        }}
                    >
                        <PlusCircleOutlined style={{fontSize: "20px", strokeWidth: "30", stroke: "whitesmoke"}}/> <div style={{marginTop: "-2px",fontSize:"20px"}}>Listing</div>
                    </Button>
                </Row>

                <Row align={'middle'} style={{marginBottom:"20px", cursor:"pointer",color:"#19376D"}} onClick={handleCreateContact}>
                    {/* <Button style={{padding:"30px 37px", lineHeight:"0px"}}><UserAddOutlined style={{ fontSize: "20px", color: "#5959dcd9", marginTop:"-8px" }} /></Button>
                    <Text style={{marginLeft:"10px", fontSize:"19px", fontWeight:"bold",color:"#19376D"}}>Add New Contact</Text> */}
                    <Button
                        style={{
                        background: "#3a74b6",
                        color: "whitesmoke",
                        fontWeight: "bold",
                        borderRadius: "1px",
                        border: "none",
                        display: "flex",
                        alignItems: "center",
                        padding:"25px 20px",
                        }}
                    >
                        <UserAddOutlined style={{fontSize: "20px", strokeWidth: "30", stroke: "whitesmoke"}}/> <div style={{marginTop: "-2px",fontSize:"20px"}}>Add New Contact</div>
                    </Button>
                </Row>

                <Row align={'middle'} style={{marginBottom:"20px", cursor:"pointer",color:"#19376D"}} onClick={handleTransactions}>
                    {/* <Button style={{padding:"30px 32px", lineHeight:"0px"}}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="30"
                        viewBox="0 0 39.103 41.547"
                        style={{marginTop:"-12px",color:"#19376D"}}
                    >
                        <g
                        id="home-property-svgrepo-com_1_"
                        data-name="home-property-svgrepo-com (1)"
                        transform="translate(0 -23.275)"
                        >
                        <g
                            id="Group_543"
                            data-name="Group 543"
                            transform="translate(0 23.275)"
                        >
                            <g
                            id="Group_542"
                            data-name="Group 542"
                            transform="translate(0 0)"
                            >
                            <path
                                id="Path_691"
                                data-name="Path 691"
                                d="M37.325,55.268H35.548V37.581l.808.526a1.777,1.777,0,1,0,1.939-2.98L20.521,23.563a1.777,1.777,0,0,0-1.939,0L4.34,32.829h0l-3.53,2.3a1.777,1.777,0,0,0,1.939,2.98l.808-.526V55.268H1.778a1.777,1.777,0,0,0,0,3.555H37.325a1.777,1.777,0,0,0,0-3.555Zm-5.332,0H7.11v-20l12.442-8.095,12.442,8.095Z"
                                transform="translate(0 -23.275)"
                            />
                            </g>
                        </g>
                        <g
                            id="Group_545"
                            data-name="Group 545"
                            transform="translate(12.229 35.878)"
                        >
                            <g id="Group_544" data-name="Group 544">
                            <path
                                id="Path_692"
                                data-name="Path 692"
                                d="M174.771,191.747a1.777,1.777,0,0,0-.52-1.257l-1.676-1.675a1.777,1.777,0,1,0-2.514,2.514l.419.419-2.727,2.727a5.326,5.326,0,1,0,2.514,2.514L174.25,193A1.778,1.778,0,0,0,174.771,191.747Zm-8.061,8.8a1.777,1.777,0,1,1,.521-1.257A1.765,1.765,0,0,1,166.709,200.544Z"
                                transform="translate(-160.123 -188.294)"
                            />
                            </g>
                        </g>
                        </g>
                    </svg>
                    </Button>
                    <Text style={{marginLeft:"10px", fontSize:"19px", fontWeight:"bold",color:"#19376D"}}>Transactions</Text> */}
                    <Button
                        style={{
                        background: "#3a74b6",
                        color: "whitesmoke",
                        fontWeight: "bold",
                        borderRadius: "1px",
                        border: "none",
                        display: "flex",
                        alignItems: "center",
                        padding:"25px 38px",
                        }}
                    >
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="30"
                        viewBox="0 0 39.103 41.547"
                        style={{margin:"-2px 7px 0 -4px"}}
                    >
                        <g
                        id="home-property-svgrepo-com_1_"
                        data-name="home-property-svgrepo-com (1)"
                        transform="translate(0 -23.275)"
                        >
                        <g
                            id="Group_543"
                            data-name="Group 543"
                            transform="translate(0 23.275)"
                        >
                            <g
                            id="Group_542"
                            data-name="Group 542"
                            transform="translate(0 0)"
                            >
                            <path
                                id="Path_691"
                                data-name="Path 691"
                                d="M37.325,55.268H35.548V37.581l.808.526a1.777,1.777,0,1,0,1.939-2.98L20.521,23.563a1.777,1.777,0,0,0-1.939,0L4.34,32.829h0l-3.53,2.3a1.777,1.777,0,0,0,1.939,2.98l.808-.526V55.268H1.778a1.777,1.777,0,0,0,0,3.555H37.325a1.777,1.777,0,0,0,0-3.555Zm-5.332,0H7.11v-20l12.442-8.095,12.442,8.095Z"
                                transform="translate(0 -23.275)"
                                fill='whitesmoke'
                            />
                            </g>
                        </g>
                        <g
                            id="Group_545"
                            data-name="Group 545"
                            transform="translate(12.229 35.878)"
                        >
                            <g id="Group_544" data-name="Group 544">
                            <path
                                id="Path_692"
                                data-name="Path 692"
                                d="M174.771,191.747a1.777,1.777,0,0,0-.52-1.257l-1.676-1.675a1.777,1.777,0,1,0-2.514,2.514l.419.419-2.727,2.727a5.326,5.326,0,1,0,2.514,2.514L174.25,193A1.778,1.778,0,0,0,174.771,191.747Zm-8.061,8.8a1.777,1.777,0,1,1,.521-1.257A1.765,1.765,0,0,1,166.709,200.544Z"
                                transform="translate(-160.123 -188.294)"
                                fill='whitesmoke'
                            />
                            </g>
                        </g>
                        </g>
                    </svg>
                        
                        <div style={{marginTop: "-2px", fontSize:"20px"}}>Transactions</div>
                    </Button>
                </Row>
            </Col>
        </Space>
    </>
  )
}

export default HomeOptions