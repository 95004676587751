import { Button, Modal, Tooltip,} from "antd";
import React, { useRef, useState } from "react";
import { EmailModal } from "./EmailModal";
import { ReadOnlyModal } from "./ReadOnlyModal";
import { PlusCircleFilled } from "@ant-design/icons"
import { useDispatch } from "react-redux";
import { PropertyHandlers } from "../state/actions";
import { useMediaQuery } from "react-responsive";

const SignableAndReadOnly = ({
      role,
      nestedModalOpen,
      setNestedModalOpen,
      currentPropertyDocId,
      setCurrentPropertyDocId,
      selectedData,
      documentId,
      openfromProperDocs,
      source,
      section,
      agentStatus,
      ispdfClone,
      delegateOwnerId,
      RTDInitiator,
      client,
      contractType,
      reloadSection,
      componentUsedSection,
      allBuyerAgentData,
      allSellerAgentData,
      selectedClient,
       docSelected,
      delegate,
      setPropertyDocsModalOpen,
      personDocs
     }) => {
  const [newState, setNewState] = useState({email:"",fullName:"",isOpen:false});
  const [ showTextArea,setShowTextArea] = useState(false);
  const [signerChecked, setSignerChecked] = useState({});
 const initialMountRef = useRef(false);
  const dispatch= useDispatch()
  let unmounted = {
    value: false
  };
  const breakPoint = useMediaQuery({ maxWidth: "570px" })
  const handleEmailCancel = () => {
    setNestedModalOpen({...nestedModalOpen,isEmailModalVisible:false});
    setNewState({email:""});
    setNewState({fullName:""});
    initialMountRef.current = false;
    setSignerChecked({});
    setShowTextArea(false)
  };
  
  return (
    <>
      {nestedModalOpen?.isEmailModalVisible && (
        <Modal
          visible={nestedModalOpen?.isEmailModalVisible}
          onCancel={handleEmailCancel}
          title={
            <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
              <span style={{ fontWeight: "bold", color: "grey", margin: "0 auto", fontSize:breakPoint?"10px":"20px" }}>
                SEND EMAIL  
                <Tooltip title="Add Read Only Recepient!" trigger={["hover", "click"]}>
                  <Button
                    type="primary"
                    size={breakPoint?"small":"medium"}
                    style={{
                      position: "absolute",
                      right: "2px",
                      color: "white",
                      
                    }}
                    onClick={() => setShowTextArea(true)}
                  >
                    Add Recipient
                  </Button>
                </Tooltip>
              </span>
            </div>
          }
          style={{ marginTop: "-50px" }}
          width={800}
          footer={false}
        >
          <EmailModal
            role={role}
            section={section}
            source={source}
            client={client}
            contractType={contractType}
            ispdfClone={ispdfClone}
            agentStatus={agentStatus}
            initialMountRef={initialMountRef}
            selectedData={selectedData}
            signerChecked={signerChecked}
            setSignerChecked={setSignerChecked}
            setCurrentPropertyDocId={setCurrentPropertyDocId}
            currentPropertyDocId={docSelected ? currentPropertyDocId : {}}
            documentId={docSelected ? documentId : ""}
            setNestedModalOpen={setNestedModalOpen}
            isEmailModalVisible={nestedModalOpen?.isEmailModalVisible}
            handleEmailCancel={handleEmailCancel}
            nestedModalOpen={nestedModalOpen}
            delegateOwnerId={delegateOwnerId}
            RTDInitiator={RTDInitiator}
            newState={newState}
            setNewState={setNewState}
            reloadSection={reloadSection}
            componentUsedSection={componentUsedSection}
            allBuyerAgentData={allBuyerAgentData}
            allSellerAgentData={allSellerAgentData}
            selectedClient={selectedClient}
            showTextArea={showTextArea}
            setShowTextArea={setShowTextArea}
            setPropertyDocsModalOpen={setPropertyDocsModalOpen}
            personDocs={personDocs}
          />
        </Modal>
      )}
    </>
  );
};

export { SignableAndReadOnly };
