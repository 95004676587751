/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Modal, Popconfirm, Row, Table, Alert, message } from "antd";
import React, { useEffect, useState } from "react";
import { PlusCircleFilled, DeleteTwoTone, ArrowLeftOutlined } from "@ant-design/icons";
import { ClientList } from "../../Client/components/ClientList";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import "../styles/offer.listin.css";
import { useHistory, useLocation } from "react-router-dom";
import { decryptUrlParams, formatPhoneNumber } from "../../Common/utils/extraFunctions";
import { EmptyData } from "../../Common/components/EmptyData";
import { Loading } from "../../Common";
import { clientAction } from "../../Client/state/actions";
import TransactionEmail from "../../Rtd/component/TransactionEmail";
import axios from "axios";
import { transactionLaneDataHandle } from "../../Rtd/state/actions";
import { PropertyHandlers } from "../state/actions";
import styles from "../styles/tasks.module.css";
import { checkIfDisabled, disabledTransactionText } from "../../Common/commondata/commonData";

function MainComp({rtdClient, section}) {
    const delegate =  (section === "DELEGATE");
    const dispatch = useDispatch();
    const [input, setInput] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const { getIndividualTransactionData } = useSelector((state) => state.rtdTransactionLane, shallowEqual);

    const { needToKnowData, needToKnowError, needToKnowLoading, updateNeedToKnow } = useSelector(state=>state.PropertyReducer);
    const history = useHistory();

    const defaultCase = {
        value: delegate,
        selected: "DELEGATE"
    }
    const [showSearchInput, setShowSearchInput] = useState(false);
    const [value, setValue] = useState("");
    const [page, setPage] = useState([1]);
    const [delegateModal, setDelegateModal] = useState({
        ...defaultCase
    });

    const handleCancel = () => {
        setInput("");
        setShowModal(false);
    };

    let unmounted = {
        value: false
    };
    let source = axios.CancelToken.source();
    useEffect(() => {
        const delegateSide = delegate && ((rtdClient === "SELLER") ? "SELLER" : "BUYER")  
        const delegateOwnerId = delegate && getIndividualTransactionData.currentAgentId?._id
        dispatch(PropertyHandlers.getNeedToknow({offerId: getIndividualTransactionData?.offerId, unmounted, source, delegateSide, delegateOwnerId}));
        
        return () => {
            unmounted.value = true;
            source.cancel("Cancelling in cleanup");
        }
    }, [updateNeedToKnow]);

    const handleClientSelected = (e) => {
        const delegateSide = delegate && ((rtdClient === "SELLER") ? "SELLER" : "BUYER")  
        setInput("");
        handleCancel();
        let ClientData = {
            clientId: e._id,
            offerId: getIndividualTransactionData?.offerId,
            propertyId: getIndividualTransactionData?.propertyId,
            transactionId: getIndividualTransactionData?.transactionId,
            ...(delegateSide ? {delegateSide} : {})
        };
        dispatch(PropertyHandlers.uploadNeedToknow({ ...ClientData }));
    };

    const columns = [
        {
            title: <span style={{ fontSize: "20px", color: "white" }}>Name</span>,
            dataIndex: "fullName",
            key: "fullName",
            render: (text) => <span style={{ width: "100%" }}>{text}</span>,
        },
        {
            title: <span style={{ fontSize: "20px", color: "white" }}>Email</span>,
            dataIndex: "email",
            key: "email",
            style: {
            fontSize: "20px",
            color: "red",
            },
        },
        {
            title: <span style={{ fontSize: "19px", color: "white" }}>PhoneNumber</span>,
            dataIndex: "mobilePhoneNumber",
            render: (mobilePhoneNumber) => <span style={{ width: "100%" }}>{formatPhoneNumber(mobilePhoneNumber?.phoneNumber)}</span>,
        },
        {
            title: (
            <span
                style={{
                fontSize: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                }}
            >
                Delete
            </span>
            ),
            dataIndex: "_Id",
            key: "_id",
            render: (text, record) => (
            <span
                style={{
                color: "red",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                }}
            >
                <Popconfirm placement="top" title="Are you sure you want to delete?" {...(checkForDisable?.disabled ? {} : {onConfirm: (event) => handleDelete(event, record)})} okText="Yes" cancelText="No">
                    <DeleteTwoTone
                        style={{
                            fontSize: "19px",
                        }}
                    />
                </Popconfirm>
            </span>
            ),
        },
    ];

    const handleDelete = (event, record) => {
        const delegateSide = delegate && ((rtdClient === "SELLER") ? "SELLER" : "BUYER")  
        let data = {
            clientId: record?._id,
            offerId: getIndividualTransactionData?.offerId,
            ...(delegateSide ? {delegateSide} : {})
        };
        dispatch(PropertyHandlers.removeNeedToknow({data}));
    };

    const handleClientSelect = ({value}) => {
        setDelegateModal({
            ...delegateModal,
            selected: value,
            value: false
        })
    }

    const rowClassName = () => "custom-row";

    const checkForDisable = checkIfDisabled({openFor: rtdClient, actualPropertyStatus: getIndividualTransactionData?.actualPropertyStatus, buyingStatus: getIndividualTransactionData?.buyingStatus, offerStatus: getIndividualTransactionData?.status});

    return (
        <>
            <Row>
                <Col style={{ width: "100%", marginBottom: "20px" }}>
                    <div
                        style={{
                            color: "#189EFF",
                            fontSize: "20px",
                            textAlign: "center",
                            borderRadius: "8px",
                            width: "100%",
                            margin: "auto",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                            padding: "0",
                            color: "gray",
                            cursor: "pointer",
                            }}
                            onClick={() => {
                                history.push(history?.location?.pathname?.replace("/needToKnow", ""));
                            }}
                        >
                            <span
                            style={{
                                marginRight: "10px",
                                fontSize: "18px",
                            }}
                            >
                            <ArrowLeftOutlined />
                            </span>
                            Back
                        </div>
                        <h3
                            style={{
                            color: "#189EFF",
                            textDecoration: "underline",
                            textUnderlineOffset: "6px",
                            }}
                        >
                            Need to Know List
                        </h3>
                        <span>
                            <PlusCircleFilled
                                {...(checkForDisable?.disabled ? {onClick: disabledTransactionText} : {onClick: () => {
                                    setDelegateModal({
                                        ...defaultCase
                                    })
                                    setPage([1]);
                                    dispatch(clientAction.clearGetClientData());
                                    setShowModal(true);
                                }})}
                            style={{
                                fontSize: "34px",
                                fill: "#085191",
                                color: "#085191",
                                // marginRight: "30px",
                            }}
                            ></PlusCircleFilled>
                        </span>
                    </div>{" "}
                    {getIndividualTransactionData?.transactionId ? (
                        <div>
                            <TransactionEmail selectedData={getIndividualTransactionData} />
                            <div style={{ marginTop: "5px", textAlign: "center" }}>
                            <Alert style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "80%", margin: "auto" }} message={'Any email sent to the address above will be copied into the transaction report. Agents and people listed on the "Need to Know" list will receive a copy of the message.'} type="info" showIcon />
                            </div>
                        </div>
                        ) : (
                        ""
                    )}
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    {needToKnowLoading ? (
                    <div
                        style={{
                        marginTop: "15%",
                        }}
                    >
                        <Loading />
                    </div>
                    ) : needToKnowError ? "" : needToKnowData && needToKnowData?.length > 0 ? (
                    <Table
                        size="small"
                        style={{
                            paddingBottom: "2rem",
                            width: "100%",
                            alignItems: "center",
                            textAlign: "center",
                            margin: "auto",
                        }}
                        scroll={{ y: "53vh" }}
                        // scroll="auto"
                        columns={columns}
                        dataSource={needToKnowData}
                        pagination={false}
                        rowClassName={rowClassName}
                    />
                    ) : needToKnowData?.length === 0 ? (
                    <div
                        style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        marginTop: "10%",
                        }}
                    >
                        <EmptyData />
                    </div>
                    ) : null}
                </Col>

                <Modal
                    visible={showModal}
                    onOk={() => {}}
                    onCancel={handleCancel}
                    footer={false}
                    style={{
                        marginTop: "-50px"
                    }}
                >
                    {
                        delegateModal?.value ? <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "20px" }}>
                            <div>
                                <span style={{ color: "#2C59A9", fontSize: "25px", cursor: "pointer", fontWeight: "bold" }}>
                                    Select contacts from
                                </span>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px", padding: "20px 0px" }}>
                                <div>
                                    <span style={{ color: "#2C59A9", fontSize: "20px", cursor: "pointer" }} onClick={()=>handleClientSelect({value: "DELEGATE"})}>
                                        {getIndividualTransactionData?.currentAgentId?.fullName}
                                    </span>
                                </div>
                                <div>
                                    <span style={{ color: "grey", fontSize: "15px" }}>
                                        ------ OR ------
                                    </span>
                                </div>
                                <div>
                                    <span style={{ color: "#2C59A9", fontSize: "20px", cursor: "pointer", display: "flex", flexDirection: "column", alignItems: "center" }} onClick={()=>handleClientSelect({value: "SELF"})}>
                                        My Contacts
                                    </span>
                                </div>
                            </div>
                        </div> : <ClientList listSource={"offerSection"} selectClient={handleClientSelected} searchBuyerNameInOffer={input} page={page} setPage={setPage} showSearchInput={showSearchInput} setShowSearchInput={setShowSearchInput} value={value} setValue={setValue} delegateOwnerId={delegate && (delegateModal?.selected === "DELEGATE") && getIndividualTransactionData?.currentAgentId?._id}/>
                    }
                </Modal>
            </Row>
        </>
    );
}

const NeedTOKnow = ({ RTDInitiater, section }) => {
    const delegate = (section === "DELEGATE");
    const { pathname, search } = useLocation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const { getIndividualTransactionError, } = useSelector((state) => state.rtdTransactionLane, shallowEqual);
    const rtdClient = (pathname?.split("/")[(pathname?.split("/")?.length) - 3] === "sDashboard") ? "SELLER" : "BUYER";
    
    let unmounted = {
        value: false
    };
    let source = axios.CancelToken.source();
    useEffect(()=>{
        let arr = pathname.split("/");
        let offerId = arr.pop();
        offerId = offerId.split("-").join("");

        const delegateSide = delegate && rtdClient;
        let delegateOwnerId = null;
        if (delegateSide) {
          const queryParams = new URLSearchParams(search);
          const encryptedOwnerId = queryParams.get("ref");
          delegateOwnerId = encryptedOwnerId ? decryptUrlParams(encryptedOwnerId) : null;
        }
        dispatch(transactionLaneDataHandle.getIndividualTransaction({offerId, setLoading, unmounted, source, delegateSide, delegateOwnerId}))
    
        return () => {
            dispatch(transactionLaneDataHandle.resetRTDData());
            unmounted.value = true;
            source.cancel("Cancelling in cleanup");
        }
    },[])

    return <div style={{display: "flex", justifyContent: "center", height: "60vh"}}>
        <div className={styles.needToKnowMain}>
            {
                loading ? <Loading /> : (
                    getIndividualTransactionError ? "" : <MainComp rtdClient={rtdClient} section={section}/>
                )
            }
        </div>
    </div>
}

export { NeedTOKnow };
