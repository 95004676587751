export const filteredTransactionData = ({ ele, selectedData, source }) => {
  let transactionData = selectedData?.newTransactionData || selectedData?.transactionData || {};
  // if (ele.prefillValues) {
  //   let allInputsToDelete = Object.keys(ele.prefillValues);
  //   console.log("allInputsToDelete", allInputsToDelete);
  //   allInputsToDelete?.forEach((element) => {
  //     delete transactionData[element];
  //   });
  // }
  if (source === "PROPERTY_DOCS") {
    delete transactionData["dateTable"];
    delete transactionData["addClauseData"];
    delete transactionData["addDocumentData"];
  }
  return selectedData;
};

export const removePersonObject = (corporateClients = []) => {

  for (let i = 0; i < corporateClients?.length; i++) {
    corporateClients[i] = {
      ...corporateClients[i],
    };
    if (corporateClients?.[i]?.isCorp) {
      corporateClients[i].signers = [...corporateClients[i].signers];
      for (let j = 0; j < corporateClients?.[i]?.signers?.length; j++) {
        corporateClients[i].signers[j] = {
          ...corporateClients[i].signers[j],
        };
        corporateClients[i].signers[j].personId = corporateClients?.[i]?.signers[j]?.personId?._id || corporateClients?.[i]?.signers[j]?.personId;
      }
    } else {
      corporateClients[i].personId = corporateClients?.[i]?.personId?._id || corporateClients?.[i]?.personId;
    }
  }
 return corporateClients || [];
};

export const processNestedDataforReadOnly = (data) => {
  let results = [];
  const isMongoDBObjectID = (id) => /^[a-f\d]{24}$/i.test(id);
  if (data && data.length) {
    data.forEach((item) => {
      if (!item?.isCorp && item?.isReadOnly) {
        if (item.personId?._id && isMongoDBObjectID(item.personId?._id)) {
          results.push({ personId: item.personId?._id });
        } else {
          results.push({
            ...(item.personId.fullName && { fullName: item.personId?.fullName }),
            ...(item.personId.email && { email: item.personId?.email }),
          });
        }
      } else if (item?.isCorp && item?.signers?.length > 0) {
        // Handle the case for corps
        item.signers.forEach((signer) => {
          if (signer.isReadOnly) {
            if (signer.personId?._id && isMongoDBObjectID(signer.personId?._id)) {
              results.push({ personId: signer.personId?._id });
            } else {
              results.push({
                ...(signer.personId?.email && { email: signer.personId.email }),
              });
            }
          }
        });
      }
    });
  }

  return results;
};

const mergeSigners = (existingSigners = [], newSigners = []) => {
  const seenSignerIds = new Set(existingSigners.map((signer) => signer.personId?._id || signer.key));
  return [
    ...existingSigners,
    ...newSigners.filter((newSigner) => {
      const signerId = newSigner.personId?._id || newSigner.key;
      return !seenSignerIds.has(signerId);
    }),
  ];
};

const processClients = (clientIds = [], corpMap, individualMap) => {
  clientIds.forEach((client = {}) => {
    const { isCorp, key, personId, signers = [] } = client;
    if (isCorp) {
      if (!corpMap.has(key)) {
        corpMap.set(key, { ...client, signers: signers || [] });
      } else {
        const existingCorpClient = corpMap.get(key);
        const mergedSigners = mergeSigners(existingCorpClient.signers, signers);
        corpMap.set(key, { ...existingCorpClient, signers: mergedSigners });
      }
    } else {
      const personIdValue = personId?._id || key;
      if (!individualMap.has(personIdValue)) {
        individualMap.set(personIdValue, { ...client, signers: signers || [] });
      }
    }
  });
};
export const getcombinedClients = ({ allContractDocuments = [], condition }) => {
  const corpSellers = new Map();
  const individualSellers = new Map();
  const corpBuyers = new Map();
  const individualBuyers = new Map();
  allContractDocuments?.forEach((document = {}) => {
    if (condition === "SELLER" || !condition) {
      processClients(document?.sellerIds, corpSellers, individualSellers);
    }
    if (condition === "BUYER" || !condition) {
      processClients(document?.buyerIds, corpBuyers, individualBuyers);
    }
  });
  return {
    combinedSellerIds: condition === "BUYER" ? [] : [...corpSellers.values(), ...individualSellers.values()],
    combinedBuyerIds: condition === "SELLER" ? [] : [...corpBuyers.values(), ...individualBuyers.values()],
  };
};

export const mapClientDocs = (docsTabDoraDocs) => {
  let clientDocuments = [];
  docsTabDoraDocs?.forEach((doc) => {
    if (doc.createdDocuments?.length > 0) {
      doc.createdDocuments.forEach((subDoc) => {
        clientDocuments.push(subDoc);
        if (subDoc.matchedDocuments?.length > 0) {
          subDoc.matchedDocuments.forEach((matchDocument) => {
            clientDocuments.push(matchDocument);
          });
        }
      });
    }
  });
  return clientDocuments;
};
export const getAgentsArrayFromIndividualTxnData = ({
  agentType = '',
  txnData = {},
}) => {
  let agents = [];
  const {newTransactionData = {}} = txnData;
  const {BuyerAgents = [], SellerAgents = []} = newTransactionData;
  if (agentType === 'SELLER_AGENT') {
   agents.push(...BuyerAgents);
  } else if (agentType === 'BUYER_AGENT') {
    agents.push(...SellerAgents);
  }
  return agents;
};
