/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { transactionLaneDataHandle } from "../state/actions";
import { Tooltip } from "antd";
import { BiShare } from "react-icons/bi";
import { RiShareForwardLine } from "react-icons/ri";
import styles from "../style/rtd.module.css";
import { useLocation } from "react-router-dom";
import { Modal } from "antd";
import moment from "moment";
import { Loading } from "../../Common/components/Loading";
import { FilePdfOutlined, CheckCircleFilled, InfoCircleOutlined, DeleteTwoTone } from "@ant-design/icons";
import { SignableAndReadOnly } from "../../PropertyComp/components/SignableAndReadOnly";
import { checkIfDisabled, conterOfferContractTypes, createOfferContractTypes, disabledTransactionText } from "../../Common/commondata/commonData";

const { confirm } = Modal;

const SignalIcon = (props) => {
  return (
    <div
      style={{
        width: 33,
        height: 30,
        borderRadius: 4,
        backgroundColor: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 4,
      }}
    >
      <div
        style={{
          width: 18,
          height: 18,
          borderRadius: 8,
          backgroundColor: props.color === "SUBMITTED" ? "green" : props.color === "DRAFT" ? "yellow" : props.color === "CANCEL" ? "red" : props.color === "INVALIDATED" ? "darkgray" : "white",
        }}
      />
    </div>
  );
};

const SellerComp = (props) => {
  let timestamp = props && props.sellerDocumentUpdatedAt;
  const delegate = props?.section === "DELEGATE";
  let date = timestamp && new Date(timestamp);
  const dateNew = timestamp && moment(date).format("MM/DD/YYYY");
  const time = timestamp && moment(date).format("h:mm a");
  const dispatch = useDispatch();

  const confirmDeleteForm = (props) => {
    confirm({
       title: 'Are you sure want to delete the form?',
      content: 'The document will be removed from Transaction Dashboard but can still be accessed from Property Docs',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        if (props?.documentId) {
          dispatch(transactionLaneDataHandle.deleteForm({payload: {...props,  isToggleFrom: "RTD"}, ...(delegate ? { delegateSide: "SELLER", delegateOwnerId: props?.selectedData?.currentAgentId?._id } : {})}));
        }
      },
      onCancel() {
        // Do nothing, just close the Modal
      },
    });
  };

  const checkForDisable = checkIfDisabled({openFor: props.RTDInitiator, actualPropertyStatus: props?.selectedData?.actualPropertyStatus, buyingStatus: props?.selectedData?.buyingStatus, offerStatus: props?.selectedData?.status});
  return (
    <div
      style={{
        flex: 1,
        justifyContent: "space-between",
        textAlign: "center",
        alignItems: "center",
        marginBottom: 0,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 17,
        }}
      >
        {/* {seller && ( */}
        <>
          <div
            style={{
              width: "100%",
              borderStyle: props.RTDInitiator === "SELLER" ? "solid" : "none",
              height: 0,
              marginTop: 12,
              position: "relative",
              // borderWidth: props.RTDInitiator === "SELLER" ? 1.2 : "",
              borderColor: props?.RTDInitiator === "SELLER" ? (props?.invalidated ? "darkgray" : "#2976be") : "white",
            }}
          >
            {props.RTDInitiator === "SELLER" &&
              props.client === "SELLER" &&
              (!props.checked.showBuyer ? (
                <></>
              ) : (
                <div style={{ position: "relative", marginTop: "-10px" }}>
                  <Tooltip title="Document sent to Buyer Agent">
                    <CheckCircleFilled style={{ fontSize: "18px", backgroundColor: "white", borderRadius: "100px", color: props?.invalidated ? "darkgray" : "green" }} />
                  </Tooltip>
                </div>
              ))}

            {/* DELETE a FORM */}
            {
              props.client === "SELLER" && props?.RTDInitiator === "SELLER" && !createOfferContractTypes.includes(props?.contractType) ? (
                <div style={{ position: "absolute", bottom: 0, left: 0, cursor: "pointer" }} {...(checkForDisable?.disabled ? { onClick: disabledTransactionText } : { onClick: () => confirmDeleteForm(props) })}>
                  <Tooltip title="Delete Form">
                    <DeleteTwoTone />
                  </Tooltip>
                </div>
              ) : null}
          </div>
          {((props.contractType === "INSP_OBJ" || props.contractType === "AVO" || props.contractType === "TRON" || props.contractType === "NTT") && props.RTDInitiator === "BUYER") || props.contractType === "TO" ? null : <SignalIcon color={props.invalidated ? "INVALIDATED" : props.sellerDocumentStatus} />}
        </>
        {/* )} */}
      </div>
      {/* {sellerDate && ( */}
      <p
        style={{
          textAlign: "left",
          marginLeft: 10,
          marginTop: props?.RTDInitiator === "BUYER" ? "-30px" : "-2px",
          fontSize: "13px",
          fontWeight: "bolder",
          // marginBottom:0
        }}
      >
        {props.sellerDocumentUpdatedAt === undefined || props.contractType === "TO" ? "" : dateNew}
      </p>
      <p
        style={{
          textAlign: "left",
          marginLeft: 20,
          marginTop: -18,
          fontSize: "13px",
          fontWeight: "bolder",
          marginBottom: 0,
        }}
      >
        {props.sellerDocumentUpdatedAt === undefined || props.contractType === "TO" ? "" : time}
      </p>
    </div>
  );
};

const BuyerComp = (props) => {
  let timestamp = props?.ispdfClone && createOfferContractTypes.includes(props?.contractType) ? props?.createdDateMili : props?.buyerDocumentUpdatedAt;
  const delegate = props?.section === "DELEGATE";
  let date = timestamp && new Date(timestamp);
  const dateNew = timestamp && moment(date).format("MM/DD/YYYY");
  const time = timestamp && moment(date).format("h:mm a");
  const dispatch = useDispatch();

  const confirmDeleteForm = (props) => {
    confirm({
       title: 'Are you sure want to delete the form?',
      content:  'The document will be removed from Transaction Dashboard but can still be accessed from Property Docs',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        if (props?.documentId) {
          dispatch(transactionLaneDataHandle.deleteForm({payload: {...props,  isToggleFrom: "RTD"}, ...(delegate ? { delegateSide: "BUYER", delegateOwnerId: props?.selectedData?.currentAgentId?._id } : {})}));
        }
      },
      onCancel() {
        // Do nothing, just close the Modal
      },
    });
  };

  const checkForDisable = checkIfDisabled({ openFor: props.RTDInitiator, actualPropertyStatus: props?.selectedData?.actualPropertyStatus, buyingStatus: props?.selectedData?.buyingStatus, offerStatus: props?.selectedData?.status });

  return (
    <div
      style={{
        flex: 1,
        justifyContent: "space-between",
        textAlign: "center",
        marginBottom: 0,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 17,
        }}
      >
        <>
          {((props.contractType === "TO" || props.contractType === "LBPOS" || props.contractType === "NTT") && props.RTDInitiator === "SELLER") || props.contractType === "TO" ? null : <SignalIcon color={props.invalidated ? "INVALIDATED" : props.buyerDocumentStatus} />}
          <div
            style={{
              width: "100%",
              borderStyle: props.RTDInitiator === "BUYER" ? "solid" : "none",
              height: 0,
              marginTop: 12,
              position: "relative",
              borderColor: props.RTDInitiator === "BUYER" ? (props?.invalidated ? "darkgray" : "#ffce51") : "white",
            }}
          >
            {props.RTDInitiator === "BUYER" &&
              props.client === "BUYER" &&
              (!props.checked.showSeller ? (
                <></>
              ) : (
                <div style={{ position: "relative", marginTop: "-10px" }}>
                  <Tooltip title="Document sent to Seller Agent">
                    <CheckCircleFilled style={{ fontSize: "18px", backgroundColor: "white", borderRadius: "100px", color: props.invalidated ? "darkgray" : "green" }} />
                  </Tooltip>
                </div>
              ))}

            {/* DELETE A FORM */}
            {props.client === "BUYER" && props?.RTDInitiator === "BUYER" &&
              (
                (createOfferContractTypes.includes(props?.contractType) && props?.selectedData?.status !== "ACCEPTED") ||
                !createOfferContractTypes.includes(props?.contractType)
              ) ? (
              <div style={{ position: "absolute", bottom: 0, right: 0, cursor: "pointer" }}
                {...(checkForDisable?.disabled ? { onClick: disabledTransactionText } : { onClick: () => confirmDeleteForm(props) })}>
                <Tooltip title="Delete Form">
                  <DeleteTwoTone />
                </Tooltip>
              </div>
            ) : null}
          </div>
        </>
        {/* )} */}
      </div>

      <p
        style={{
          textAlign: "end",
          marginRight: 10,
          marginTop: props?.RTDInitiator === "BUYER" ? "-10px" : "-30px",
          fontSize: "13px",
          fontWeight: "bolder",
        }}
      >
        <Tooltip title={props.ispdfClone && createOfferContractTypes.includes(props?.contractType) ? "Offer Imported Date" : null} placement="top">
          {dateNew && props.contractType !== "TO" ? dateNew : ""}
        </Tooltip>
      </p>
      <p
        style={{
          textAlign: "right",
          marginRight: 20,
          marginTop: -18,
          fontSize: "13px",
          fontWeight: "bolder",
          marginBottom: 0,
        }}
      >
        <Tooltip title={props.ispdfClone && createOfferContractTypes.includes(props?.contractType) ? "Offer Imported Date" : null} placement="top">
          {time && props.contractType !== "TO" ? time : ""}
        </Tooltip>
      </p>
    </div>
  );
};

const DetailComp = ({ doc, seller, role, selectedData, ispdfClone, handleCheckboxChangeBuyer, section, client, currentAgentDetails, allBuyerAgentData, allSellerAgentData }) => {
  const delegate = (section === "DELEGATE");

  seller = {...selectedData, ...seller};
  delete seller.selectedData;
  const [nestedModalOpen, setNestedModalOpen] = useState({
    isEmailModalVisible: false,
  });
  const location = useLocation();
 
  let dispatch = useDispatch();
  const handleSendEmailIconClick = (event) => {
    // event.stopPropagation();
    if (!nestedModalOpen.isEmailModalVisible) {
      setNestedModalOpen({
        ...nestedModalOpen,
        isEmailModalVisible: true,
        sendMultipleDoc: true,
      });
    }
  };

  const urlShortningForDraft = () => {
    if (seller?.ispdfClone || seller?.isPdf) {
      let fileUrl = seller?.resureSignedPdfLink ? seller?.resureSignedPdfLink : seller?.pdfLink;
      window.open(fileUrl, "_blank").focus();
    } else {
      let buildData = {
        builtForId: currentAgentDetails?._id,
        key: currentAgentDetails?._id,
        builtForSection: "DOCUMENT",
        signable: true,
        openFor: role === "SELLER" ? "SELLER_AGENT" : "BUYER_AGENT",
        contractType: seller?.contractType,
        offerId: selectedData?.offerId || null,
        documentId: seller?.documentId,
        contractId: seller?.contractId,
        token: localStorage.getItem("userAuth"),
        ...(delegate ? {delegateOwnerId: currentAgentDetails?._id} : {})
      };
      dispatch(transactionLaneDataHandle.getDraftDocumentData(buildData, location?.pathname));
    }
  };

  return (
    <div
    // style={{
    //   display: "flex",
    //   alignItems: "center",
    // }}
    >
       <SignableAndReadOnly componentUsedSection="TRANSACTION_DASHBOARD" nestedModalOpen={nestedModalOpen} setNestedModalOpen={setNestedModalOpen} role={role} source={ role === "SELLER" ? "SELLING_RTD" : "BUYING_RTD"}  selectedData={seller} documentId={seller.documentId} ispdfClone={ispdfClone} section={section} delegateOwnerId={delegate && currentAgentDetails?._id} allBuyerAgentData={allBuyerAgentData} allSellerAgentData={allSellerAgentData}   docSelected={true}
       reloadSection={"RTD"}
       client={client}
       RTDInitiator={role === "SELLER" ? "SELLER" : "BUYER"}/>
      <div
        style={{
          width: "310px",
          borderStyle: "solid",
          borderWidth: 2,
          borderRadius: 8,
          borderColor: seller?.invalidated ? "darkgray" : seller?.RTDInitiator === "SELLER" ? "#3376a8" : "#ffce51",
          textAlign: "center",
          fontSize: "18px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "4px",
          marginBottom: "4px",
          color: "#8e8e8e",
          fontWeight: 200,
        }}
      >
        <p
          style={{
            marginLeft: "2px",
            cursor: "pointer",
            height: "45px",
            paddingTop: "16px",
          }}
        >
          {/* <span>{role === "SELLER" && !seller?.invalidated && <BiShare size={25} style={{ color: "#376ada" }} onClick={(event) => handleSendEmailIconClick(event)} />}</span>

          <span>
            {seller?.RTDInitiator === "BUYER" &&
            seller?.client === "BUYER" &&
            ( 
              <Tooltip title={seller?.invalidated ? null : "Send Document to Seller Agent"}>
                {
                  seller?.invalidated ? <BiShare size={25} /> : <BiShare size={25} style={{ color: "#376ada" }} onClick={() => handleCheckboxChangeBuyer(seller?.contractType, seller?.RTDInitiator)} />
                }
              </Tooltip>
            )}
          </span> */}
        </p>

        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <p
            onClick={() => {
              urlShortningForDraft();
            }}
            className={styles.documentName}
            style={{
              textAlign: "center",
              margin: "0%",
              height: "45px",
              cursor: "pointer",
              padding: 0,
              fontWeight: 500,
            }}
          >
            <span style={{ ...(seller?.invalidated ? { textDecoration: "line-through" } : {}) }}>{doc}</span> {ispdfClone ? createOfferContractTypes.includes(seller?.contractType) ? <FilePdfOutlined /> : "" : seller?.isPdf && <FilePdfOutlined />}
          </p>
          {!createOfferContractTypes.includes(seller?.contractType) &&
            (seller?.invalidated ? (
              <Tooltip title="Invalidated">
                <InfoCircleOutlined style={{ color: "red" }} />
              </Tooltip>
            ) : (
              ""
            ))}
        </div>

        <p
          style={{
            marginRight: "2px",
            height: "45px",
            paddingTop: "16px",
            cursor: "pointer",
            textAlign: "center",
          }}
        >
          {/* {role === "BUYER" && !seller?.invalidated && <RiShareForwardLine style={{ color: "#376ada" }} size={25} onClick={(event) => handleSendEmailIconClick(event)} />}

          <span>
            {seller?.RTDInitiator === "SELLER" &&
              seller?.client === "SELLER" &&
              (<Tooltip title={seller?.invalidated ? null : "Send Document to Buyer Agent"}>{seller?.invalidated ? <RiShareForwardLine size={25} /> : <RiShareForwardLine style={{ color: "#376ada" }} size={25} onClick={() => handleCheckboxChangeBuyer(seller?.contractType, seller?.RTDInitiator)} />}</Tooltip>)}
          </span> */}
        </p>
      </div>
    </div>
  );
};

function RtdRow(props) {
  // console.log(props,"props")
  
  // not show tr in rtd
  const { contractType, section } = props;
  const delegate = section === "DELEGATE";
  const [nestedModalOpen, setNestedModalOpen] = useState({
    isEmailModalVisible: false,
    isAgent: false,
    sendMultipleDoc: false,
  });
  const { updateDocumentLoading } = useSelector(
    (state) => state.rtdTransactionLane
  );

  const checked = {
    showBuyer: props.showBuyer,
    showSeller: props.showSeller,
}


  const handleCheckboxChangeBuyer = (contractType, RTDInitiater) => {
    if (!nestedModalOpen.isEmailModalVisible) {
      setNestedModalOpen({
        ...nestedModalOpen,
        isEmailModalVisible: true,
        // isAgent: true,
      });
    }
  };

  return (
    <>
      {updateDocumentLoading && (
        <div style={{ position: "fixed", left: "43%", top: "50%" }}>
          <Loading />
        </div>
      )}

      {contractType !== "TR" && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <SignableAndReadOnly
            nestedModalOpen={nestedModalOpen}
            setNestedModalOpen={setNestedModalOpen}
            source={props?.client === "SELLER" ? "SELLING_RTD" : "BUYING_RTD"}
            selectedData={props?.selectedData}
            documentId={props?.documentId}
            ispdfClone={props?.ispdfClone}
            docSelected={true}
            section={section}
            role={props?.client}
            delegateOwnerId={delegate && props?.currentAgentDetails?._id}
            RTDInitiator={props?.RTDInitiator}
            client={props?.client}
            contractType={props?.contractType}
            reloadSection={"RTD"}
            allBuyerAgentData={props?.allBuyerAgentData || []}
            allSellerAgentData={props?.allSellerAgentData || []}
          />
          <SellerComp {...props} checked={checked} handleCheckboxChangeBuyer={handleCheckboxChangeBuyer} section={section} />

          <DetailComp section={section} seller={props} doc={props.contractName} role={props.client} selectedData={props.selectedData} ispdfClone={props.ispdfClone} handleCheckboxChangeBuyer={handleCheckboxChangeBuyer} client={props?.client}
              currentAgentDetails={props?.currentAgentDetails}
            allBuyerAgentData={props?.allBuyerAgentData || []}
            allSellerAgentData={props?.allSellerAgentData || []} />

          <BuyerComp section={section} {...props} checked={checked} handleCheckboxChangeBuyer={handleCheckboxChangeBuyer} />
        </div>
      )}
    </>
  );
}

export default RtdRow;
