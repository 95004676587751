/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Form, Modal, Button, Typography, Row, Col, message, Tooltip, Image, List, Empty } from "antd";
import { documentAction } from "../state/actions";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { PropertySearch } from "./PropertySearch";
import moment from "moment";
import "../style/DocumentForm.css";
import style from "../style/DocumentSection.module.css";
import { capitalizeStr, formatPhoneNumber, formatAddress, generateFullNameFromObj } from "../../Common/utils/extraFunctions";
import { ClientList } from "../../Client/components/ClientList";
import { BuildDocButton } from "./BuildDocButton";
import { PropertySearchBtn } from "./PropertySearchBtn";
import { SelectDate } from "./SelectDate";
import { PriceInput } from "./PriceInput";
import { TemplateInput } from "./TemplateInput";
import { PropertyTypeInput } from "./PropertyTypeInput";
import houseImage from "../../Assets/imagePlaceholder.webp";
import { useMediaQuery } from "react-responsive";
import ManualEntry from "./ManualEntry";
import { Signers } from "./Signers";
import { v4 } from "uuid";
import DelegateUser from "./DelegateUser";
const { Text } = Typography;
function validateMessages(label) {
  return {
    required: `${label} is required!`,
    types: {
      email: `${label} is not a valid email!`,
      number: `${label} is not a valid number!`,
    },
  };
}

function DocumentForm({ progress, setProgress, redirectFromClient, delegate }) {
  const dispatch = useDispatch();
  let history = useHistory();
  const [propertySearch, setPropertySearch] = useState(false);
  const [manualPropertyModal, setManualPropertyModal] = useState(false);

  const { templateData } = useSelector((state) => state.template);
  const [showClientListModal, setShowClientListModal] = useState(false);
  const [templateId, setTemplateId] = useState(null);

  const [fieldEmpty, setFieldIsEmpty] = useState({
    templateType: "",
    propertyType: "",
    closingDateType: null,
    documentType: "66ab7a6a7bfcc2a4deedb08d", // new CBSR 2024
    priceType: null,
    searchBuyerType: "",
    clientsArray: [],
    showClientListModal: false,
    entityName: "",
    currentlySelectedCorp: {},
    delegateBuyer:null,
    property: {}
  });

  const { formFields, setFormFields } = redirectFromClient;

  const { getCategoryData } = useSelector((state) => state.documentSection.categoryReducer, shallowEqual);

  const [brokerLicenseId, setBrokerLicenseId] = useState("");
  const [showSearchInput, setShowSearchInput] = useState(true);

  const [isownerDetailModal, setisownerDetailModal] = useState(false);
  const [value, setValue] = useState("");
  const [page, setPage] = useState([1]);

  const isMobile = useMediaQuery({ maxWidth: 480 });
  const isTab = useMediaQuery({ maxWidth: 950 });
  const delegateOwnerId = fieldEmpty?.delegateBuyer?.id;

  let { ownwersData, ownwersDataLoading } = useSelector(
    (state) => state.documentSection.PropertySearchReducer
  );
  const { onFocusData } = useSelector((state) => state.documentSection.onFocusReducer, shallowEqual);
  const { getUserDetailsData, userId, userName } = useSelector((state) => state.account);
  const { getLicenseData } = useSelector((state) => state.account);
  let { clientsArray, currentlySelectedCorpData, isCorpTrue } = useSelector((state) => state.documentSection.CorpReducer);
  const [form] = Form.useForm();
  const [listingForm, setListingForm] = useState({
    email: "",
    firstName: "",
    middleName: "",
    lastName: "",
    streetNumber: null,
    streetName: "",
    unit: null,
    directionPrefix: "",
    directionSuffix: "",
    stateOrProvince: "CO",
    city: "",
    postalCode: null,
  });
  // Sending office details to CBSR
  let officeDetails = {};
  officeDetails["officeLogo"] = getUserDetailsData?.brokerageData?.brokerageLogo || "";
  let nameOffice = getUserDetailsData?.brokerageData?.brokerageName?.trim() || "";
  let formattedAddress = getUserDetailsData?.brokerageData?.brokerageAddress || "";
  // let nameandAddress = nameOffice ? `${nameOffice} - ${formattedAddress}` : formattedAddress || "";
  // officeDetails["nameandAddress"] = nameandAddress;
  officeDetails["name"] = nameOffice;
  officeDetails["address"] = formattedAddress;

  useEffect(() => {
    if (getLicenseData?.length > 0) {
      for (let i = 0; i < getLicenseData?.length; i++) {
        if (getLicenseData[i].idType === "COLORADO STATE LICENSE") {
          setBrokerLicenseId(getLicenseData[i]?.id?.toUpperCase());
        }
      }
    }
  }, [getLicenseData]);

  useEffect(() => {
    if(getCategoryData?.length && redirectFromClient.isOfferModal){
      redirectFromClient.form.setFieldsValue({propertyType: formFields?.name})
    }
  },[redirectFromClient.isOfferModal,formFields,getCategoryData,redirectFromClient.form])

  let newDataBuyerList = [history?.location?.state?.clientInfo];

  // In this useEffect we are checking fieldEmpty.documentType is not undefined then we are setting up
  // value of documentType & documentTypeColor in handleOnBlurChange function if fieldEmpty values are changed
  useEffect(() => {
    if (fieldEmpty.documentType === undefined) {
      handleOnBlurChange({
        documentType: fieldEmpty.documentType === undefined ? false : true,
        documentTypeColor: "blue",
      });
    }
  }, [fieldEmpty]);
  // In this useEfffect we are setting up the progress percentage based on filed values are filled or not
  useEffect(() => {
    // progressField we create a array as same name of fieltEmpty on form field name so we can match
    let progressField = delegate ? ["documentType", "closingDateType", "priceType", "searchBuyerType", "propertyType", "delegateBuyer"] : ["documentType", "closingDateType", "priceType", "searchBuyerType", "propertyType"];

    let progressBarUpdated = 0;
    // iteratting forEach loop on progressfield and if field Empty[element] values are not null , empty,or undefined then updating state by setProgress
    progressField.forEach((element) => {
      if (fieldEmpty[element]) {
        progressBarUpdated = delegate ?  progressBarUpdated + 17 : progressBarUpdated + 20;
      }
    });
    setProgress(progressBarUpdated);
  }, [fieldEmpty, onFocusData]);
  // search buyer input field search icon click function and opening modal
  const onSearch = (name, value, key) => {
    setShowClientListModal(!showClientListModal);
    const fieldEmptyUpdated = fieldEmpty;
    fieldEmptyUpdated[key] = value;
    setFieldIsEmpty(fieldEmptyUpdated);
  };
  useEffect(() => {
    dispatch(documentAction.onFocusData(fieldEmpty));
    if (newDataBuyerList[0] !== undefined) {
      const fieldEmptyUpdated = fieldEmpty;
      fieldEmptyUpdated["searchBuyerType"] = newDataBuyerList[0]._id;
      setFieldIsEmpty({ ...fieldEmptyUpdated });
    }
  }, []);

  const handleCancel = () => {
    setShowClientListModal(false);
  };

  // when the user select any client in the clientList
  const handleClientSelected = (selectedClient) => {
    if (!selectedClient?.email) {
      message.error("Please add the client's email to proceed!");
      return;
    }
    const clients = clientsArray || [];

    if (clients.length >= 10) {
      message.error(`Maximum number of clients already added.`);
      return;
    }

    const isCorp = currentlySelectedCorpData?.isCorp;
    const existingIndex = clientsArray.findIndex((el) => el.key === currentlySelectedCorpData?.key);

    if (isCorpTrue && existingIndex !== -1) {
      const existingSigners = clientsArray[existingIndex].signers || [];
      if (existingSigners?.some((el) => el.personId === selectedClient._id)) {
        message.error(`Client already exists in the signers list.`);
        return;
      }
     
      clientsArray[existingIndex].signers = [...(existingSigners || []), { fullName: generateFullNameFromObj(selectedClient), personId: selectedClient._id }];
      dispatch(documentAction.setCorpState({ clientsArray }));
      message.success("Signer added!");
    } else if (!isCorp && clients.some((client) => client.personId === selectedClient._id)) {
      message.error(`Client already exists in the Buyers list.`);
      return;
    } else {
      clientsArray.push({
        fullName: generateFullNameFromObj(selectedClient),
        personId: selectedClient._id,
        isCorp: false,
        signers: [],
      });
      dispatch(documentAction.setCorpState({ clientsArray }));
      message.success("Buyer added!");
    }

    onChange(selectedClient._id, "searchBuyerType");
  };

  const handleAdd = () => {
    setShowClientListModal(false);
  }

  const handleDeleteClientOrCorp = (element, type, item) => {
    if (type === "CORP") {
      handleCorpDelete(element);
      return;
    } else if (type === "SIGNERS") {
      let newDataIndex = clientsArray.findIndex((el) => el.key === element.key);
      if (newDataIndex !== -1) {
        // console.log("coming inside this one", clientsArray[newDataIndex].signers, item);
        clientsArray[newDataIndex].signers = clientsArray[newDataIndex].signers.filter((signer) => signer.personId !== item.personId);
      }
    } else {
      clientsArray = clientsArray.filter((el) => el.personId !== element.personId);
    }
    dispatch(documentAction.setCorpState({ clientsArray: clientsArray }));
    if(clientsArray?.length === 0){
        setProgress((prevState)=> delegate ? prevState-17 : prevState-20);
    }
  };

  const handleCorpDelete = (element = {}) => {
    Modal.confirm({
      title: `Are you sure you want to remove ${element.fullName} from this form?`,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      content: "Note: This will also remove all the signers associated with it.",
      onOk() {
        let newData = clientsArray?.filter((el) => el.key !== element.key);
        dispatch(documentAction.setCorpState({ clientsArray: newData }));
      },
      onCancel() {},
    });
  };

  function onChange(value, key) {
    const fieldEmptyUpdated = fieldEmpty;
    fieldEmptyUpdated[key] = value;
    setFieldIsEmpty({ ...fieldEmptyUpdated });
  }

  function onPriceChange(value, key) {
    const fieldEmptyUpdated = fieldEmpty;
    fieldEmptyUpdated[key] = value;
    setFieldIsEmpty({ ...fieldEmptyUpdated });
  }

  let handleForm = (event) => {};

  let handleOnfocusChange = (object) => {
    dispatch(documentAction.onFocusData(object));
  };
  let handleOnBlurChange = (object) => {
    dispatch(documentAction.onFocusData(object));
  };

  let handlePropSearchModel = () => {
    setPropertySearch(!propertySearch);
  };

  let handleOkPropertySearch = () => {
    setManualPropertyModal(false);
  };

  let handleCancelPropertySearch = () => {
    setPropertySearch(false);
    setManualPropertyModal(false);
    dispatch(documentAction.onBackbuttonClick());
    // setOfferCheck(!offercheck);
  };


  const propertySearchResults = fieldEmpty?.property;
  
  let sellersArr = [];
  if (propertySearchResults?.sellerIds?.length) {
    sellersArr= propertySearchResults?.sellerIds
  }

  let delegateBuyer = fieldEmpty?.delegateBuyer;

  const BuyerAgents=[{
    personId: delegate ? delegateBuyer?.id : userId,
    fullName: delegate ? delegateBuyer?.fullName : userName,
    email: delegate ? delegateBuyer?.email : getUserDetailsData && (getUserDetailsData?.email || ""),
    firmName: delegate ? delegateBuyer?.delegateBrokerageName : getUserDetailsData?.brokerageData?.brokerageName || "",
    firmLicense: delegate ? delegateBuyer?.licenseNumber : getUserDetailsData?.brokerageData?.brokerageLicenseNumber || "",
    license:  delegate ? delegateBuyer?.licenseId : brokerLicenseId,
    address:  delegate ? delegateBuyer?.address : getUserDetailsData?.brokerageData?.brokerageAddress || "",
    phoneNumber: delegate ? formatPhoneNumber(delegateBuyer?.phoneNumber) : formatPhoneNumber(getUserDetailsData?.mobilePhoneNumber?.phoneNumber),
    faxNumber: formatPhoneNumber(getUserDetailsData?.brokerageData?.brokerageFaxNumber,"FAX") || "",
  }];
  // buildData for url shortening for build document from offer screen
  let buildData = {
    builtForId:delegate ? delegateBuyer?.id : userId,
    key: delegate ? delegateBuyer?.id : userId,
    builtForSection: "DOCUMENT",
    signable: true,
    openFor: "BUYER_AGENT",
    contractType: formFields?.contractType,
    contractId: formFields?.contractId,
    RTDInitiator: "BUYER",
    token: localStorage.getItem("userAuth"),
    ...(delegate ? { delegateOwnerId } : {}),
    ...(propertySearchResults?._id ? { propertyId: propertySearchResults?._id } : {}),
    ...(propertySearchResults?.thirdpartyId ? { thirdpartyId: propertySearchResults?.thirdpartyId } : {}),
    ...(templateId ? { templateId: templateId } : {}),
    urlData: {
      officeDetails: officeDetails,
      // buyer and seller details
      Buyers: clientsArray,
      Sellers: sellersArr,
      BuyerAgents,
      SellerAgents: propertySearchResults?.SellerAgents || [],
      // other details
      PurchasePrice: fieldEmpty?.priceType,
      ClosingDate: moment(fieldEmpty?.closingDateType).format("YYYY-MM-DD"),
      FullAddress: propertySearchResults?.fullAddress || "",
      CountyOrParish: propertySearchResults?.county || "",
      // parcelNumber: propertySearchResults?.parcelNumber || "",
      // listingDate: propertySearchResults?.listingDate || "",
      EarnestMoneyHeldBy: propertySearchResults?.earnestMoneyHolder || "",
      TaxLegalDescription: propertySearchResults?.legalDescription || "",
      ParkingFacilities: propertySearchResults?.ParkingFacilities || "",
      StorageFacilities: propertySearchResults?.StorageFacilities || "",
      GarageRemotesIncluded: propertySearchResults?.GarageRemotesIncluded || "",
      InclusionsAttached: propertySearchResults?.InclusionsAttached || "",
      OtherInclusions: propertySearchResults?.OtherInclusions || "",
      EncumberedInclusions: propertySearchResults?.EncumberedInclusions || "",
      LeasedItems: propertySearchResults?.LeasedItems || "",
      Exclusions: propertySearchResults?.Exclusions || "",
      DeededWaterRightsChecked: propertySearchResults?.DeededWaterRightsChecked || "",
      DeededWaterRightsLegalDescription: propertySearchResults?.DeededWaterRightsLegalDescription || "",
      DeededWaterRightsDeedType: propertySearchResults?.DeededWaterRightsDeedType || "",
      OtherRightsRelatingToWaterChecked: propertySearchResults?.OtherRightsRelatingToWaterChecked || "",
      OtherRightsRelatingToWaterDescription: propertySearchResults?.OtherRightsRelatingToWaterDescription || "",
      WellRightsChecked: propertySearchResults?.WellRightsChecked || "",
      WellPermitNumber: propertySearchResults?.WellPermitNumber || "",
      WaterStockCertificatesChecked: propertySearchResults?.WaterStockCertificatesChecked || "",
      WaterStockCertificatesDescription: propertySearchResults?.WaterStockCertificatesDescription || "",
      AdditionalProvisions: propertySearchResults?.AdditionalProvisions || "",
      TitleConveyedBy: propertySearchResults?.TitleConveyedBy || "",
      TitleConveyedBy__Other: propertySearchResults?.TitleConveyedBy__Other || "",
      AssociationFeeNoUnpaidExcept: propertySearchResults?.AssociationFeeNoUnpaidExcept || "",
      SellerIsForeignPersonChecked: propertySearchResults?.SellerIsForeignPersonChecked || "",
      EarnestMoney: propertySearchResults?.EarnestMoney || "",
      EarnestMoneyForm: propertySearchResults?.EarnestMoneyForm || "",
      ListingPeriodBeginDate: propertySearchResults?.ListingContractDate || "",
      GrowingCropsDescription: propertySearchResults?.GrowingCropsDescription || "",
      ListingElectronicNoticeAdditionalOption: propertySearchResults?.ListingElectronicNoticeAdditionalOption || "",
    },
  };

  const handleManualEntryBtn = () => {
    form.resetFields()
    setListingForm({
      email: "",
      firstName: "",
      middleName: "",
      lastName: "",
      streetNumber: null,
      streetName: "",
      unit: null,
      directionPrefix: "",
      directionSuffix: "",
      stateOrProvince: "CO",
      city: "",
      postalCode: null,
    });
    setManualPropertyModal(true);
  };

  const PriceComponent = (price, type) => {
    const formattedPrice = price.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    return formattedPrice;
  };

  const handleCloseOwnerModal = () => {
    setisownerDetailModal(false)
  }

  const CustomEmptyMessage = () => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span style={{ fontWeight: "bold", fontSize: "20px", color: "grey" }}>Sorry, we couldn't find owner information for that property. Please enter Seller information on the form manually.</span>} />;

  const handleOneOwnersSelected = (item) => {
    const isCompany = item?.OwnerTypeDescription1?.toLowerCase() === "company";
    let sellerIds = []
    item.Owners.forEach((name) => {
      sellerIds.push({
        fullName: name,
        personId: null,
        isCorp: isCompany,
        signers: [],
        key: v4(),
      });
    });
    setFieldIsEmpty({
      ...fieldEmpty,
      property: {
        ...fieldEmpty.property,
        sellerIds
      }
    })
    handleCloseOwnerModal();
  }

  return (
    <>
      {/* Property search modal  */}
      {propertySearch && (
        <Modal width={isMobile ? "100%" : isTab ? "60%" : "43%"} style={{ marginTop: "-50px" }} visible={propertySearch} onOk={handleOkPropertySearch} onCancel={handleCancelPropertySearch} footer={null} afterClose={() => dispatch(documentAction.clearFormProperty("resetForm"))}>
          <PropertySearch handleCancelPropertySearch={handleCancelPropertySearch} fieldEmpty={fieldEmpty} setFieldIsEmpty={setFieldIsEmpty} setisownerDetailModal={setisownerDetailModal} redirectFromClient={redirectFromClient} />
        </Modal>
      )}

      {/* owner Details Modal */}
      <Modal open={isownerDetailModal} footer={false} style={{ marginTop: "-50px" }} title={<p style={{ display: "flex", justifyContent: "center" }}>PLEASE SELECT VALID OWNER</p>} onCancel={handleCloseOwnerModal} maskClosable={false} closable={false}>
        <div style={{ height: "500px", overflow: "hidden", overflowY: "auto" }}>
          <List
            dataSource={ownwersData}
            loading={ownwersDataLoading}
            renderItem={(item) => (
              <List.Item className={style.listItem}>
                <div onClick={() => handleOneOwnersSelected(item)} style={{ lineHeight: "17px", padding: "5px" }}>
                  <p style={{ fontWeight: "bold" }}>{item?.PropertyAddressFull}</p>
                  <p style={{ display: "flex", flexDirection: "column" }}>
                    {item?.Owners?.map((name) => (
                      <>
                        <span style={{ fontSize: "25px" }}>&#x2022;</span>
                        <span style={{ marginLeft: "15px", marginTop: "-15px" }}>{name}</span>
                      </>
                    ))}
                  </p>
                </div>
              </List.Item>
            )}
            locale={{ emptyText: <CustomEmptyMessage /> }}
          />
        </div>
        <div style={{ textAlign: "center" }}>
          <Button onClick={handleCloseOwnerModal} type="primary">
            Cancel
          </Button>
        </div>
      </Modal>

      {/* Manual Entry Modal */}
      <Modal width={isMobile ? "100%" : isTab ? "75%" : "50%"} style={{ marginTop: "-50px" }} title={<p style={{ textAlign: "center", color: "grey", fontSize: "25px" }}>Manual Entry</p>} visible={manualPropertyModal} onOk={handleOkPropertySearch} onCancel={handleCancelPropertySearch} footer={null} afterClose={() => dispatch(documentAction.clearFormProperty("resetForm"))}>
        <ManualEntry handleCancelPropertySearch={handleCancelPropertySearch} form={form} listingForm={listingForm} setListingForm={setListingForm} fieldEmpty={fieldEmpty} setFieldIsEmpty={setFieldIsEmpty} redirectFromClient={redirectFromClient} />
      </Modal>

      {/* Adding Buyers */}
      <Modal visible={showClientListModal} onOk={() => {}} onCancel={handleCancel} footer={false} style={{ marginTop: "-80px" }}>
        <ClientList listSource={"offerSection"} selectClient={handleClientSelected} setshowModal={setShowClientListModal} page={page} setPage={setPage} showSearchInput={showSearchInput} setShowSearchInput={setShowSearchInput} value={value} setValue={setValue} clientsArray={clientsArray} isCorpTrue={isCorpTrue} currentlySelectedCorpData={currentlySelectedCorpData} delegateOwnerId={delegateOwnerId} />
        <Button type="primary" style={{ bottom: 0, left: 175, position: "relative", minWidth: "10vw", marginTop: "20px" }} onClick={handleAdd} disabled={isCorpTrue && currentlySelectedCorpData?.signers?.length > 0 ? false : !isCorpTrue && clientsArray?.filter((el) => el.isCorp === false)?.length > 0 ? false : true}>
          Add
        </Button>
      </Modal>

      {/* property details box */}
      {fieldEmpty?.property?.propertyType && (
        <div className={style.box}>
          <div className={style.addressHeading}>{fieldEmpty?.property?.fullAddress}</div>
          <div className={style.boxContent} style={{ display: "flex" }}>
            <div className={style.imgBox}>
              <Image src={typeof fieldEmpty?.property?.propertyImages?.[0]?.imageUrl == "string" && fieldEmpty?.property?.propertyImages?.[0]?.imageUrl !== "" ? fieldEmpty?.property?.propertyImages?.[0]?.imageUrl : houseImage} alt="home" />{" "}
            </div>
            <div className={style.addressbox}>
              {fieldEmpty?.property?.SellerAgents?.[0]?.fullName ? (
                <p>
                  <span>Agent: </span>
                  <Text style={{ width: "160px", fontWeight: 500, color: "#2976be" }} ellipsis>
                    {" "}
                    {fieldEmpty?.property?.SellerAgents?.[0]?.fullName}{" "}
                  </Text>
                </p>
              ) : null}
              {fieldEmpty?.property?.listingId ? (
                <p>
                  <span>Listing Id: </span>
                  {fieldEmpty.property.listingId}
                </p>
              ) : null}

              {fieldEmpty?.property?.listingPrice ? (
                <p>
                  <span>Price: </span>
                  {PriceComponent(fieldEmpty.property.listingPrice, fieldEmpty.property.propertyType[0])}
                </p>
              ) : null}

              {fieldEmpty?.property?.propertyType?.length > 0 ? (
                <p>
                  <span>Source: </span>
                  {fieldEmpty.property.propertyType[0] === "PRIVATE" ? "RE-Sure" : fieldEmpty.property.propertyType[0]}
                </p>
              ) : null}

              {fieldEmpty?.property?.PropertyFor ? (
                <p>
                  <span>Property Type: </span>
                  {fieldEmpty.property.PropertyFor}
                </p>
              ) : null}
            </div>
            {fieldEmpty?.property?.sellerIds && fieldEmpty?.property?.sellerIds?.length > 0 && (
              <>
                <div style={{ border: "1px solid #2976be" }}></div>
                <div className={style.addressbox}>
                  <p style={{ fontSize: "17px", fontWeight: "bold" }}>Property Owners:</p>
                  {fieldEmpty?.property?.sellerIds &&
                    fieldEmpty?.property?.sellerIds?.map((el, index) => (
                      <Tooltip title={el?.fullName?.trim()} key={index}>
                        <p className={style.ownerNames}>
                          {index + 1}. {el?.fullName?.trim()}
                        </p>
                      </Tooltip>
                    ))}
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <Form
        className="formaccnt"
        form={redirectFromClient.form}
        layout="vertical"
        validateMessages={validateMessages}
        style={{ marginTop: "20px" }}
        scrollToFirstError
        onFinish={handleForm}
        initialValues={{
          buyer: "",
          price: "",
        }}
      >
        <Row gutter={16}>{fieldEmpty?.property?.propertyType && <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginBottom: "1rem" }}></Col>}</Row>
        {delegate && (
          <Row gutter={16}>
            {/* Select Delegate User  */}
            <DelegateUser onChange={onChange} handleOnBlurChange={handleOnBlurChange} handleOnfocusChange={handleOnfocusChange} fieldEmpty={fieldEmpty} />
          </Row>
        )}
        {delegate && <hr style={{ marginTop: "-5px" }} />}
        <Row gutter={16}>
          {/* Property Type Input  */}
          <PropertyTypeInput handleOnBlurChange={handleOnBlurChange} handleOnfocusChange={handleOnfocusChange} fieldEmpty={fieldEmpty} onChange={onChange} setFormFields={setFormFields} setFieldIsEmpty={setFieldIsEmpty} />
          {/* Template Input  */}
          <TemplateInput handleOnfocusChange={handleOnfocusChange} setTemplateId={setTemplateId} templateData={templateData} />
        </Row>
        <Row gutter={16}>
          {/* Price Input  */}
          <PriceInput handleOnfocusChange={handleOnfocusChange} handleOnBlurChange={handleOnBlurChange} fieldEmpty={fieldEmpty} onPriceChange={onPriceChange} />
          {/* Closing Date */}
          <SelectDate handleOnfocusChange={handleOnfocusChange} handleOnBlurChange={handleOnBlurChange} fieldEmpty={fieldEmpty} onChange={onChange} redirectFromClient={redirectFromClient} />
        </Row>
        <Row>
          <Signers showClientListModal={showClientListModal} setShowClientListModal={setShowClientListModal} setPage={setPage} onSearch={onSearch} handleDeleteClientOrCorp={handleDeleteClientOrCorp} setProgress={setProgress} progress={progress} delegateOwnerId={delegateOwnerId} delegate={delegate} onChange={onChange} />
        </Row>
        <Row gutter={16} align="end" style={{ marginTop: "2rem", gap: "10px" }}>
          <PropertySearchBtn handleOnfocusChange={handleOnfocusChange} handleOnBlurChange={handleOnBlurChange} handlePropSearchModel={handlePropSearchModel} fieldEmpty={fieldEmpty} />
          <span style={{ fontSize: "18px", color: "grey", margin: isMobile || isTab ? "-5% 45% 0 0" : "" }}>OR</span>
          <Button type="primary" onClick={handleManualEntryBtn} style={{ width: isTab || isMobile ? "100%" : "" }}>
            Manual Entry
          </Button>
          <BuildDocButton progress={progress} buildData={buildData} delegate={delegate} />
        </Row>
      </Form>
    </>
  );
}
export { DocumentForm };
