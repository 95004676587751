import { message } from "antd";
import moment from "moment";
import CryptoJS from "crypto-js";

/* -----------function for encryptiong the id ---------------- */
export const encryptStr = (str) => {
  let n = str?.length;
  let newStr = "";
  for (let i = 0; i < n; i++) {
    if (i !== 0 && i % 4 === 0) {
      newStr += str[i] + "-";
    } else {
      newStr += str[i];
    }
  }
  return newStr;
};

export const capitalizeName = (name) => {
  if (name) {
    return name
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  return "";
};
export const capitalizeFirst = (name) => {
  if (name) {
    return name
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }
  return "";
};

/* -----------function to format a 10 digit phone no in the US format ---------------- */
export const formatPhoneNumber = (phoneNumber,source) => {
  if (!phoneNumber) {
    return "";
  }
  // removing all non-digit characters
  const cleanedPhoneNo = phoneNumber.toString().replace(/\D/g, "");

  if(source?.length && cleanedPhoneNo?.length < 10){
    return `${cleanedPhoneNo}`;
  }

  if (cleanedPhoneNo?.length === 10) {
    return `(${cleanedPhoneNo.substring(0, 3)}) ${cleanedPhoneNo.substring(3, 6)} - ${cleanedPhoneNo.substring(6, 10)}`;
  } else {
    return "";
  }
};

// paste function
function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    throw new Error("You can only upload JPG/PNG file!");
  }
  const isLt5M = file.size / 1024 / 1024 < 5;
  if (!isLt5M) {
    throw new Error("Image must be smaller than 5MB!");
  }
  return isJpgOrPng && isLt5M;
}

export const PasteEventFunction = (event) => {
  let stringPasted = "";
  let count = 0;
  let error = null;
  var items = (event.clipboardData || event.originalEvent.clipboardData).items;
  for (let index in items) {
    var item = items[index];
    if (item.kind === "file" && count === 0) {
      var blob = item.getAsFile();
      try {
        beforeUpload(blob);
      } catch (err) {
        stringPasted = true;
        error = err.message;
        break;
      }
      var reader = new FileReader();
      const ide = Math.floor(10000 + Math.random() * 90000);
      const imageName = "pastedImage" + ide;
      var updatedBlob = new File([blob], imageName, { type: blob.type });

      reader.onload = function (event) {
        // Handle image loading
      };
      reader.readAsDataURL(blob);
      count = +count + 1;
    }
  }

  if (error) {
    message.error(error);
    return null;
  } else if (stringPasted) {
    return "sizeError";
  }
  if (updatedBlob) {
    return updatedBlob;
  }
};

/* -----------function to format the company adderess ---------------- */
export const formatAddress = (addressObj, condition) => {
 
  if (!addressObj) {
    return "";
  }
  let addressPart1 = "";
  if (addressObj.streetNumber) {
    addressPart1 += addressObj.streetNumber;
  }
  if (addressObj.unit && addressObj.streetName) {
    addressPart1 += ` ${addressObj.streetName}, Unit ${addressObj.unit}`;
  } else if (addressObj.streetName) {
    addressPart1 += ` ${addressObj.streetName}`;
  }
  if (condition === "PART1") {
    return addressPart1.trim();
  }

  let addressPart2 = "";
  if (addressObj.city) {
    addressPart2 += `${addressObj.city}, `;
  }
  if (addressObj.state) {
    addressPart2 += `${addressObj.state}`;
  }
  if (addressObj.zipCode) {
    addressPart2 += ` ${addressObj.zipCode}`;
  }
  if (condition === "PART2") {
    return addressPart2.trim();
  }

  return `${addressPart1.trim()}, ${addressPart2.trim()}`;
};

export const formatStreetAddress = (fullAddress) => {
  if (fullAddress && typeof fullAddress === "string" && fullAddress.length > 0) {
    const addressParts = fullAddress.split(",");
    const streetAddress = addressParts?.slice(0, -2)?.join(",");
    return streetAddress;
  }
  return "";
};

// To detect if device is mobile
function isMobileApp() {
  return /(android|iphone|ipad|ipod)/i.test(navigator.userAgent) && /app/i.test(navigator.userAgent);
}
// Checking if the user is on a mobile device
const isMobileDevice = /Mobi|Android|iPhone|iPod|iPad|Windows Phone/i.test(navigator.userAgent);
// to get the more Accuracy
export function detectMobile() {
  return isMobileApp() || isMobileDevice;
}

export const generateInitials = (fullName = "") => {
  const init = fullName?.trim().split(" ");
  if (!init) return "";
  const firstInitial = init[0]?.[0]?.toUpperCase()
  const lastInitial = init.length > 1 ? init[init.length - 1]?.[0]?.toUpperCase() : "";

  return firstInitial + lastInitial;
};
export const generateInitialsFromObj = (item) => {
  const { fullName, firstName, lastName } = item || {};
  if (!fullName && !firstName && !lastName) return "";

  let firstInitial = "";
  let lastInitial = "";

  if (fullName) {
    const init = fullName.trim().split(" ");
    firstInitial = init[0]?.[0]?.toUpperCase() || "";
    lastInitial = init.length > 1 ? init[init.length - 1]?.[0]?.toUpperCase() : "";
  } else {
    if (firstName) {
      firstInitial = firstName.trim()[0]?.toUpperCase() || "";
    }
    if (lastName) {
      lastInitial = lastName.trim()[0]?.toUpperCase() || "";
    }
  }

  return firstInitial + lastInitial;
};

export const capitalizeStr = (str) => {
  if (typeof str !== 'string' || !str.length) {
    return "";
  }
  return str[0].toUpperCase() + (str.length > 1 ? str.substring(1).toLowerCase() : "");
};

export const generateFullNameFromObj = (item) => {
  const { fullName, firstName, middleName, lastName } = item || {};
  
  if (typeof fullName === 'string' && fullName.trim()) {
    return fullName.trim();
  }

  const capitalizedFirstName = capitalizeStr(firstName);
  const capitalizedMiddleName = middleName && middleName !== "null" ? capitalizeStr(middleName) : "";
  const capitalizedLastName = capitalizeStr(lastName);

  const formattedFullName = [capitalizedFirstName, capitalizedMiddleName, capitalizedLastName].filter(Boolean).join(" ");
  return formattedFullName;
};

export const formatClientsForDocs = (clientIds = []) => {
  let clients = [];
  clientIds?.forEach((item) => {
    let newItem = {
      fullName: item.fullName,
      isCorp: item.isCorp,
    };
    if (item.isCorp) {
      newItem.key = item.key;
      newItem.signers = item.signers.map((signer) => ({
        fullName: signer.fullName,
        personId: signer.personId?._id || null,
        ...(signer.personId ? {} : { key: signer.key }),
      }));
    } else {
      newItem.personId = item.personId?._id || null;
      if (!item.personId) {
        newItem.key = item.key;
      }
    }
    clients.push(newItem);
  });
  return clients;
};
export const getClientsProfileImage = (clientIds = []) => {
  let clientObj = clientIds?.[0] || {};
  if (clientObj.isCorp) {
    let newObj = clientObj.signers[0];
    return newObj.personId.profileImg?.original || "";
  } else {
    return clientObj.personId?.profileImg?.original || "";
  }
};

export const getfirstClientDetails = (clientIds = []) => {
  const clientObj = clientIds[0] || {};
  const { personId, isCorp } = clientObj;
  if (isCorp) {
    const newObj = clientObj.signers?.[0] ||{};
    const { fullName } = newObj.personId || newObj;
    const profileImg = newObj.personId?.profileImg?.original || "";
    const initials = generateInitials(fullName);
    return { fullName, initials, profileImg };
  } else {
    const { fullName } = personId || clientObj;
    const profileImg = personId?.profileImg?.original || "";
    const initials = generateInitials(fullName);
    return { fullName, initials, profileImg };
  }
};

export const generateDataForShowclientOnTop = (clientIds = []) => {
  const clientObj = clientIds[0] || {};
  const { personId, isCorp, signers } = clientObj;
  if (isCorp && signers && signers.length > 0) {
    const newObj = signers[0];
    const stateObj = {
      pathname: `/contacts`,
      state: {
        clientId: newObj.personId?._id,
        fullName: newObj.personId?.fullName || newObj.fullName ||"",
      },
    };
    return newObj?.personId?._id ? { redirect: true, stateObj } : { redirect: false };
  } else {
    const stateObj = {
      pathname: `/contacts`,
      state: {
        clientId: personId?._id,
        fullName: personId?.fullName || clientObj.fullName ||"",
      },
    };
    return personId?._id ? { redirect: true, stateObj } : { redirect: false };
  }
};

export function detectWebView() {
  return window?.ReactNativeWebView != null;
}

export const filterEmptyAndUndefinedValues = (data) => {
  if (!data || typeof data !== 'object' ) {
    return {};
  }
  return Object.fromEntries(
    Object.entries(data).filter(([key, value]) => value !== "" && value !== undefined)
  );
};

export const formatDate = (date) => {
  if (!date ||  !moment(date).isValid()) {
    return "";
  }
  return moment(date).format("MM-DD-YYYY")
};

export const getCoAgentsIds = (agentData) => {
  let agentIds = [];
  if (Array.isArray(agentData) && agentData.length) {
    agentData.forEach(el => {
      agentIds.push(el._id);
    })
  }
  return agentIds;
}
 // converting the time format into the 12h format
export const convertTime = (time) => {
  if (time && time !== "undefined") {
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjusting hours
    }
    return time.join('');
  } else {
    return "";
  }
}



export const encryptUrlParams = (params) => {
  try {
    if (!params) return "";
    const encryptedParams = CryptoJS.AES.encrypt(JSON.stringify(params), "url resure encryption").toString();
    return encodeURIComponent(encryptedParams); 
  } catch (error) {
    console.error("Error encrypting URL parameters:", error);
    return ""; 
  }
};

export const decryptUrlParams = (encryptedParams) => {
  try {
    if (!encryptedParams) return null;
    const decodedParams = decodeURIComponent(encryptedParams);
    const decryptedBytes = CryptoJS.AES.decrypt(decodedParams, "url resure encryption");
    const decryptedParams = decryptedBytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedParams);
  } catch (error) {
    console.error("Error decrypting URL parameters:", error);
    return null; 
  }
};

export const formatAgentDetails = (agent={}) => ({
  fullName: agent.fullName,
  email: agent.email,
  firmName: agent.brokerageData?.name || '',
  firmLicense: agent.brokerageData?.licenseNumber || '',
  license: agent.licenseId || '',
  address: agent.brokerageData?.address || '',
  phoneNumber: agent.mobilePhoneNumber?.phoneNumber || '',
  faxNumber: agent.brokerageData?.faxNumber || '',
  personId: String(agent._id) || '',
});

// function to format all of the Agents for autofilling in the documents.
export const formatAllSelerAgentsDetailsFun = ({ persistId, offerSellerAgentIds = [], transactionData = {} } = {}) => {
  let sellerAgentIdsArray = [];
  let SellerAgents = [];
  if (!persistId) {
    sellerAgentIdsArray = (offerSellerAgentIds || []).map((el) => String(el._id));
    SellerAgents = (offerSellerAgentIds || []).map((el) => formatAgentDetails(el));
  } else {
    sellerAgentIdsArray = offerSellerAgentIds.map((el) => String(el._id));

    SellerAgents = offerSellerAgentIds.map((el) => {
      const existingAgent = transactionData.SellerAgents?.find((ele) => ele.personId === String(el._id));
      return existingAgent || formatAgentDetails(el);
    });
  }

  const transactionDataFields = {
    SellerAgents: SellerAgents || [],
  };

  return {
    sellerAgentIds: sellerAgentIdsArray,
    transactionDataFields,
  };
};

export const capitalizeFullname = (name) => {
  if (name) {
    return name.toUpperCase(); 
  }
  return "";
};

export function getInitials(fullName) {
  if (!fullName) return ''; 
  const nameParts = fullName.trim().split(' ');
  const firstNameInitial = nameParts[0]?.[0]?.toUpperCase() || ''; 
  const lastNameInitial = nameParts.length > 1 ? nameParts[nameParts.length - 1]?.[0]?.toUpperCase() : ''; 
  return firstNameInitial + lastNameInitial;
}


export const splitFullName = (fullName) => {
  if (!fullName?.trim() || typeof fullName !== 'string') {
    return { firstName: "", middleName: "", lastName: "" };
  }

  const nameParts = fullName.trim().split(/\s+/); 
  const firstName = nameParts?.[0] || "";           
  const middleName = nameParts?.length === 3 ? nameParts?.[1] : ""; 
  const lastName = nameParts?.length >= 2 ? nameParts?.[nameParts.length - 1] : ""; 

  return { firstName, middleName, lastName };
};

