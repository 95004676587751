import React, { useEffect, useState } from 'react';
import { Col, Image, Row, Tabs } from 'antd';
import { ApartmentOutlined, FileDoneOutlined } from "@ant-design/icons";
import {  useSelector } from 'react-redux';
import { DefaultScreen } from './DefaultScreen';
import styles from "./styles/ClientScreen.module.css"
import { Calendar } from '../PropertyComp/components/Calendar';
const { TabPane } = Tabs;

export default function ClientScreen() {
 
  const [activeTab, setActiveTab] = useState('dashboard');
  const { getUserDetailsData } = useSelector((state) => state.account);

  useEffect(() => {
    if (getUserDetailsData?.builtForSection === "SHARE_CALENDAR") {
      setActiveTab("taskCalendar");
    }
  }, [getUserDetailsData])
  
  return (
    <div >
      <Row
        gutter={[4, 4]}
        style={{
          // marginLeft: "unset",
          marginRight: "5px",
          marginTop: "10px",
          marginLeft: "5px",
          // width: "100%",
          overflowX:"hidden"
         
        }}
      >
        <Col
          span={24}
          xs={24}
          sm={24}
          md={24}
          lg={7}
          xl={6}
          
        >
        <div 
        style={{
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            borderRadius: "8px",
           width:"100%",
            border: "1px solid  rgba(0,0,0,0.2)",
          padding:"0.5rem",
          height:"100%"
          }}>
          <div
           className={styles.firsttab}
          >
             {getUserDetailsData?.propertyData?.propertyImages?.[0]?.imageUrl ?
              <Image
                width={"100%"}
                height={200}
                src={getUserDetailsData?.propertyData?.propertyImages?.[0]?.imageUrl ? getUserDetailsData?.propertyData?.propertyImages?.[0]?.imageUrl : ""}
                alt="home"
              />
              : ""
            }
            {getUserDetailsData?.propertyData?.address}
          </div>

          {/* Dashboard Tab */}
          <div
            className="modal_row"
            style={{
              marginTop: "3px",
              marginBottom: "14px",
              backgroundColor: activeTab === 'dashboard' ? "#96c8f6" : "transparent",
              marginRight: "23px",
              borderRadius: "5px",
            }}
            onClick={() => setActiveTab('dashboard')}
          >
            <div className="icon_div">
              <FileDoneOutlined style={{ fontSize: "22px", color: "black" }} />
            </div>
            <div className="content_div">
              <p className="modalContent" style={{ marginLeft: "20px" }}>Dashboard</p>
            </div>
          </div>
            
           {/* Task/Calendar Tab */}
            <div className="modal_row"
              style={{
                backgroundColor: activeTab === 'taskCalendar' ? "#96c8f6" : "transparent",
                color: activeTab === 'taskCalendar' ? "#96c8f6" : "grey",
                marginRight: "23px",
                borderRadius: "5px",
                pointerEvents: (!getUserDetailsData?.builtForSection || getUserDetailsData?.builtForSection !== "SHARE_CALENDAR") ? "none" : "all"
              }}
              onClick={() => setActiveTab('taskCalendar')}
            >
              <div className="icon_div">
                <ApartmentOutlined style={{ fontSize: "22px", color: "black" }} />
              </div>
              <div className="content_div">
                <p className="modalContent" style={{ marginLeft: "20px" }}>Task/Calendar</p>
              </div>
            </div>
          </div>
        </Col>
        <Col span={24} xs={24} sm={24} md={24} lg={17} xl={18}>
          <div className="OfferTopContainer" style={{ height: "calc(100vh - 4.4rem)", padding: "0.5rem", overflowY: "hidden",
           boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", borderRadius: "8px", width: "100%", border: "1px solid  rgba(0,0,0,0.2)", paddingBottom: "2rem" }}>
            {activeTab === 'dashboard' && <DefaultScreen />}
            {activeTab === 'taskCalendar' &&
              <div>
                <Tabs defaultActiveKey="1" centered
                // tabBarExtraContent={<div style={{ margin: "5px 5px 0 0" }}>
                //   <Help tabName="CLIENT_SCREEN" />
                // </div>}
                >
                  <TabPane tab={<div>Calendar</div>} key="1" style={{ width: "100%", height: "100%", fontSize: "10px" }}>
                    <Calendar agentType={getUserDetailsData?.openFor} viewType={'month'} screen={"CLIENT_SCREEN"} offerId={getUserDetailsData?.offerId} />
                  </TabPane>
                  <TabPane tab={<div>Task</div>} key="2" disabled style={{ width: "100%", height: "100%" }}>
                  </TabPane>
                </Tabs>
              </div>
            }
          </div>
        </Col>
      </Row>
    </div>
  );
}
